<template>
  <div>
    <div class="header-title">{{ $route.meta.title }}</div>
    <div class="">
      <div>
        <div class="btn-con">
          <button
            :disabled="appAdmin"
            type="button"
            class="next-btn next-medium next-btn-primary"
            style="margin-right: 8px"
            @click="drawer = true"
          >
            <span class="next-btn-helper">创建应用</span>
          </button>
          <el-input
            v-model="page.search"
            prefix-icon="el-icon-search"
            size="small"
            style="width: 300px"
            placeholder="输入名称/client_uuid"
            clearable
            @keyup.enter.native="get_list('reset')"
            @clear="get_list('reset')"
          />
          <div
            style="float: right"
            dir="ltr"
            role="gridcell"
            class="next-col next-col-offset-0 sc-ifAKCX ezKSUK action-bar-right"
          >
            <button
              type="button"
              class="next-btn next-medium next-btn-normal isOnlyIcon"
              @click="get_list('reset')"
            >
              <i class="el-icon-refresh-right" />
            </button>
          </div>
        </div>
      </div>
      <div class="list-box">
        <Loading :show="loading">
          <el-table :data="tableData" border style="width: 100%">
            <el-table-column type="selection" align="center" width="55" />
            <el-table-column prop="date" label="名称">
              <template slot-scope="{ row }">
                <button
                  type="button"
                  class="next-btn next-medium next-btn-primary next-btn-text"
                  style="margin-right: 8px"
                  @click="handle(row)"
                >
                  {{ row.name }}
                </button>
              </template>
            </el-table-column>
            <el-table-column prop="client_uuid" label="client_uuid" />
            <el-table-column prop="user_changed_callback_url" label="应用用户变更后的回调url" />
            <el-table-column prop="permission_changed_callback_url" label="权限变动回调接口" />
            <el-table-column prop="parent_client_name" label="父级应用名称" />
            <el-table-column prop="create_time" label="创建时间" />
            <el-table-column prop="remark" label="备注" />
            <el-table-column label="操作" min-width="50">
              <template slot-scope="{ row }">
                <button
                  :disabled="appAdmin"
                  type="button"
                  class="next-btn next-medium next-btn-primary next-btn-text"
                  style="margin-right: 8px"
                  @click="delete_client(row)"
                >
                  <span class="next-btn-helper">删除</span>
                </button>
              </template>
            </el-table-column>
          </el-table>
          <div style="padding: 20px 10px 10px; font-size: 18px">
            <div style="text-align: right">
              <el-pagination
                :page-size="page.pageSize"
                :current-page="page.pageNum"
                background
                layout="total, prev, pager, next"
                :total="total"
                @current-change="handleCurrentChange"
              />
            </div>
          </div>
        </Loading>
      </div>

      <el-drawer
        ref="drawer"
        custom-class="drawer-warp"
        title="新增应用"
        :visible.sync="drawer"
      >
        <div class="drawer__content">
          <el-form
            ref="ruleForm"
            :model="form"
            :rules="rules"
            class="applications-ruleForm"
          >
            <el-form-item label="名称" prop="name">
              <el-input v-model="form.name" placeholder="请输入" />
            </el-form-item>
            <el-form-item label="首页" prop="home_page">
              <el-input v-model="form.home_page" placeholder="请输入" />
            </el-form-item>
            <el-form-item label="父级应用名称">
              <el-select v-model="form.parent_client_uuid" placeholder="请选择" style="width:100%" clearable>
                <el-option
                  v-for="item in parentList"
                  :key="item.client_uuid"
                  :label="item.name"
                  :value="item.client_uuid" />
              </el-select>
            </el-form-item>
            <el-form-item label="注销" prop="revoke_url">
              <el-input v-model="form.revoke_url" placeholder="请输入" />
            </el-form-item>
            <el-form-item label="权限回调接口" prop="user_changed_callback_url">
              <el-input v-model="form.user_changed_callback_url" placeholder="请输入" />
            </el-form-item>
            <el-form-item label="权限变动回调接口" prop="permission_changed_callback_url">
              <el-input v-model="form.permission_changed_callback_url" placeholder="请输入" />
            </el-form-item>

            <el-form-item label="添加用户回调接口" prop="add_user_url">
              <el-input v-model="form.add_user_url" placeholder="请输入地址" class="input-with-select" clearable>
                <el-select
                  slot="prepend"
                  v-model="form.add_user_method"
                  placeholder="请选择方法"
                  style="width:125px"
                  clearable
                >
                  <el-option label="POST" :value="1" />
                  <el-option label="DELETE" :value="2" />
                </el-select>
              </el-input>
            </el-form-item>

            <el-form-item label="删除用户回调接口" prop="del_user_url">
              <el-input v-model="form.del_user_url" placeholder="请输入地址" class="input-with-select" clearable>
                <el-select
                  slot="prepend"
                  v-model="form.del_user_method"
                  placeholder="请选择方法"
                  style="width:125px"
                  clearable
                >
                  <el-option label="POST" :value="1" />
                  <el-option label="DELETE" :value="2" />
                </el-select>
              </el-input>
            </el-form-item>

            <el-form-item label="备注" prop="remark">
              <el-input v-model="form.remark" :autosize="{ minRows: 4, maxRows: 4}" type="textarea" placeholder="请输入" />
            </el-form-item>
          </el-form>
        </div>
        <div class="panel-footer-drawer custom-panel-footer">
          <div class="btn-con" style="float: left">
            <button
              type="button"
              class="next-btn next-medium next-btn-primary"
              style="margin-right: 8px"
              @click="submit('ruleForm')"
            >
              <span class="next-btn-helper">确定</span>
            </button>
            <button
              type="button"
              class="next-btn next-medium next-btn-normal"
              style="margin-right: 8px"
              @click="closeDrawer('ruleForm')"
            >
              <span class="next-btn-helper">取消</span>
            </button>
          </div>
        </div>
      </el-drawer>
    </div>
  </div>
</template>

<script>
import {
  applications_list,
  rem_client,
  add_client,
  all_client_drop_down_menu
} from '@/api/applications'
import { isExternal } from '@/utils/validate'

export default {
  data () {
    const checkExternal = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('请输入首页地址'))
      }
      if (isExternal(value)) {
        callback()
      } else {
        callback(new Error('请输入正确的首页地址'))
      }
    }

    const noCheckExternal = (rule, value, callback) => {
      if (value) {
        if (isExternal(value)) {
          callback()
        } else {
          callback(new Error('请输入正确的地址'))
        }
      } else {
        callback()
      }
    }

    return {
      checked: false,
      search: '',
      loading: false,
      tableData: [],
      current: 1,
      total: 0,
      drawer: false,
      form: {},
      rules: {
        name: [{ required: true, message: '请输入名称', trigger: 'blur' }],
        home_page: [{ validator: checkExternal, required: true, trigger: 'blur' }],
        add_user_url: [{ validator: noCheckExternal, trigger: ['blur', 'change'] }],
        del_user_url: [{ validator: noCheckExternal, trigger: ['blur', 'change'] }]
      },
      page: {
        search: '',
        pageNum: 1,
        pageSize: 10
      },
      parentList: []
    }
  },
  computed: {
    // 是否应用管理员
    appAdmin () {
      const obj = this.$store.state.userInfo

      return !obj.is_super
    }
  },
  created () {
    this.get_list()
    this.get_parent_list()
  },
  methods: {
    get_parent_list () {
      all_client_drop_down_menu().then(res => {
        console.log(res)
        this.parentList = res.data
      })
    },

    async get_list (reset) {
      if (reset == 'reset') {
        this.page.pageNum = 1
      }

      this.loading = true
      const params = this.page
      const data = await applications_list(params)
      this.loading = false
      this.tableData = data.data
      this.total = data.count
    },

    submit (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          const postData = {
            name: this.form.name,
            home_page: this.form.home_page,
            revoke_url: this.form.revoke_url,
            user_changed_callback_url: this.form.user_changed_callback_url,
            remark: this.form.remark,
            permission_changed_callback_url: this.form.permission_changed_callback_url,
            parent_client_uuid: this.form.parent_client_uuid,
            add_user_url: this.form.add_user_url,
            del_user_url: this.form.del_user_url,
            add_user_method: this.form.add_user_method,
            del_user_method: this.form.del_user_method
          }
          add_client(postData).then(res => {
            if (res.code == 0) {
              this.$message({
                showClose: true,
                message: res.msg,
                type: 'success'
              })
            }
            this.drawer = false
            this.get_list()
            this.$refs[formName].resetFields()
            this.get_parent_list()
          })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    closeDrawer (formName) {
      this.$refs[formName].resetFields()
      this.drawer = false
    },
    handle (row) {
      console.log(row)
      this.$router.push({
        path: '/applications/details',
        query: { client_uuid: row.client_uuid }
      })
    },
    delete_client (row) {
      this.$confirm('是否确认删除?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        rem_client(row.client_uuid).then((res) => {
          this.$message({
            showClose: true,
            message: res.msg,
            type: 'success'
          })
          this.get_list()
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        })
      })
    },

    handleCurrentChange (val) {
      this.page.pageNum = val
      this.get_list()
    }
  }
}
</script>

<style scoped>
.btn-con >>> .el-input {
  vertical-align: middle;
}
.applications-ruleForm >>>.input-with-select .el-input-group__prepend {
    background-color: #fff;
  }
</style>
